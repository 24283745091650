const getQueryValue = (name: string): string | number | undefined => {
  // parse the '?something=123&something_else=321' part of the url
  const params = new URLSearchParams(window.location.search);

  // check for any force-enabled/disabled toggles via url query param
  const urlValue = params.get("ft-" + name);
  const sessionStorageValue = sessionStorage.getItem("ft-" + name);
  const localStorageValue = localStorage.getItem("ft-" + name);

  // requested value
  if (urlValue !== null) {
    return urlValue;
  } else if (sessionStorageValue !== null) {
    return sessionStorageValue;
  } else if (localStorageValue !== null) {
    return localStorageValue;
  }

  return;
};

export const isEnabled = (
  name: string,
  ftToggles: Record<string | symbol, boolean> = {}
): boolean => {
  let ftName = name;

  if (ftName.startsWith("ft-")) {
    // eslint-disable-next-line no-console
    console.warn(
      '"ft-" does not have to be included in this function call, it is just an url prefix to avoid name collisions'
    );
    ftName = ftName.replace(/^ft-/, "");
  }

  if (!(ftName in ftToggles)) {
    // eslint-disable-next-line no-console
    console.warn(`No default feature toggle is defined for ${ftName}, treating toggle as OFF.`);
    return false;
  }

  const queryValue = getQueryValue(ftName);
  const enabled = queryValue === "true" || queryValue === 1 || queryValue === "1";

  if (queryValue === undefined) {
    // return default value (can be either true or false)
    return !!ftToggles[ftName];
  }

  return enabled;
};

export default isEnabled;
